import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/seo";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";

const SalVitae = () => {
  const data = useStaticQuery(graphql`
    query {
      sal1: file(name: { eq: "sal_02" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sal2: file(name: { eq: "sal_01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sal3: file(name: { eq: "sal_03" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout>
        <SEO title="Sal Vitae" />
        <Title text="Sal Vitae" />
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 20px;
            margin-top: 20px;
          `}
        >
          <Img
            fluid={data.sal1.childImageSharp.fluid}
            key={data.sal1.name}
            alt={data.sal1.name}
          />
          <Img
            fluid={data.sal2.childImageSharp.fluid}
            key={data.sal2.name}
            alt={data.sal2.name}
          />
          <Img
            fluid={data.sal3.childImageSharp.fluid}
            key={data.sal3.name}
            alt={data.sal3.name}
          />
        </div>
        <Return />
      </Layout>
    </>
  );
};

export default SalVitae;
